import './meetings.css'
import skillsData from './data/skillsData'
import Skills from './Skills'

function Meetings() {
  return (
    <div>
      <div className="meetings-main-text">
        <p>
          В моей карьере удалось продолжительно поработать
          маркетологом-аналитиком у крупного производителя пластмассовых
          изделий. Также работаю медиа пленнером в крупнейшем рекламном
          агентстве. Опыт работы с клиентами FMCG и Фарма сектором, а также в
          сегменте бытовой техники. В последние полтора года занимаюсь
          frontend-разработкой
        </p>
      </div>
      <div className="skills-main">
        <h1>Мои скиллы</h1>
        <div className="skills-grid">
          {skillsData.map((skill) => {
            return <Skills key={skill.id} {...skill} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Meetings
