const skillsData = [
  {
    id: 1,
    skills: 'JavaScript',
    image:
      'https://fuzeservers.ru/wp-content/uploads/4/0/5/405ede2e7c7345a90bbab489418629d4.png',
  },
  {
    id: 2,
    skills: 'HTML',
    image:
      'https://luxe-host.ru/wp-content/uploads/3/9/4/394bd6373620272a2f9cce2fc3d77b5d.png',
  },
  {
    id: 3,
    skills: 'CSS',
    image:
      'https://i.pinimg.com/originals/79/09/89/7909897ceea2691e5a4942766c678ff3.png',
  },
  {
    id: 4,
    skills: 'Git',
    image:
      'https://www.pinclipart.com/picdir/big/147-1475273_hot-to-reset-reinitialise-a-git-repository-git.png',
  },
  {
    id: 5,
    skills: 'React',
    image:
      'https://www.pvsm.ru/images/2018/11/06/kak-razvernut-okrujenie-dlya-razrabotki-prilojenii-na-React-Native.png',
  },
]

export default skillsData
