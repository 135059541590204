import './portfolio.css'
import portfolioData from './data/portfolioData'
import Projects from './Projects'

function Portfolio() {
  return (
    <div className="portfolio-main">
      <h1>Портфолио</h1>

      <div className="portfolio-grid">
        {portfolioData.map((project) => {
          return <Projects key={project.id} {...project} />
        })}
      </div>
    </div>
  )
}

export default Portfolio
