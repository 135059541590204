const portfolioData = [
  {
    id: 1,
    project: 'nibh in hac',
    image: 'http://dummyimage.com/1280x720.png/cc0000/ffffff',
  },
  {
    id: 2,
    project: 'lobortis est phasellus sit',
    image: 'http://dummyimage.com/1280x720.png/dddddd/000000',
  },
  {
    id: 3,
    project: 'convallis morbi',
    image: 'http://dummyimage.com/1280x720.png/cc0000/ffffff',
  },
  {
    id: 4,
    project: 'lectus aliquam sit amet',
    image: 'http://dummyimage.com/1280x720.png/cc0000/ffffff',
  },
  {
    id: 5,
    project: 'mauris morbi non lectus aliquam',
    image: 'http://dummyimage.com/1280x720.png/cc0000/ffffff',
  },
  {
    id: 6,
    project: 'justo in hac habitasse',
    image: 'http://dummyimage.com/1280x720.png/ff4444/ffffff',
  },
  {
    id: 7,
    project: 'massa quis augue luctus',
    image: 'http://dummyimage.com/1280x720.png/ff4444/ffffff',
  },
  {
    id: 8,
    project: 'vestibulum vestibulum',
    image: 'http://dummyimage.com/1280x720.png/ff4444/ffffff',
  },
  {
    id: 9,
    project: 'non quam nec dui',
    image: 'http://dummyimage.com/1280x720.png/dddddd/000000',
  },
  {
    id: 10,
    project: 'dolor sit amet consectetuer adipiscing',
    image: 'http://dummyimage.com/1280x720.png/5fa2dd/ffffff',
  },
]
export default portfolioData
