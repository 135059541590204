function Skills({ image, skills }) {
  return (
    <div className="skill-card">
      <img src={image} alt="skill" />
      <p>{skills}</p>
    </div>
  )
}

export default Skills
