function Projects({ image, project }) {
  return (
    <div className="portfolio-card">
      <img src={image} alt="projects" />
      <p>{project}</p>
    </div>
  )
}

export default Projects
