import './education.css'

function Education() {
  return (
    <div className="education-main">
      <h1>Образование</h1>
      <div className="education-cards">
        <div className="education-card">
          <h6>РГУ нефти и газа им. И.М. Губкина</h6>
        </div>
        <div className="education-card">
          <h6>Responsive Web Design, FreeCodeCamp</h6>
        </div>
        <div className="education-card">
          <h6>JavaScript Algorithms and Data Structures, FreeCodeCamp</h6>
        </div>
        <div className="education-card">
          <h6>JavaScript, Sololearn</h6>
        </div>
      </div>
    </div>
  )
}

export default Education
