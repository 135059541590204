import './contacts.css'

function Contacts() {
  return (
    <div className="contacts-main">
      <h1>Контакты</h1>
      <div className="contacts-cards">
        <div className="contact-card">
          <p>VK</p>
          <a href="https://vk.com/nickelus93" target="_blank">
            Link
          </a>
        </div>
        <div className="contact-card">
          <p>WhatsApp</p>
          <a href="https://wa.me/79688232486" target="_blank">
            Link
          </a>
        </div>
        <div className="contact-card">
          <p>GitHub</p>
          <a href="https://github.com/Nickelus" target="_blank">
            Link
          </a>
        </div>
      </div>
    </div>
  )
}
export default Contacts
