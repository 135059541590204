import './App.css'
import Navbar from './components/NavBar/Navbar'
import MainDisplay from './components/MainDisplay/MainDisplay'
import Meetings from './components/Meetings/Meetings'
import Education from './components/Education/Education'
import Portfolio from './components/Portfolio/Portfolio'
import Contacts from './components/Contacts/Contacts'

function App() {
  return (
    <div className="App">
      <Navbar />
      <MainDisplay />
      <Meetings />
      <Education />
      <Portfolio />
      <Contacts />
    </div>
  )
}

export default App
