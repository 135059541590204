import './main-display.css'

function MainDisplay() {
  return (
    <div id="main" className="main-display">
      <div className="main-display-text">
        <div className="main-display-text-form">
          <p>
            Привет! <br />
            Меня зовут Смирнов Николай <br />И я web-разработчик
          </p>
          <button>Напишите мне</button>
        </div>
      </div>
      <div className="main-display-photo"></div>
    </div>
  )
}

export default MainDisplay
