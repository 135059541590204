import logo from '../../images/main-logo.png'

function Navbar() {
  return (
    <div className="main-navbar-style">
      <img src={logo} alt="My Logo" />
      <div className="navbar-style">
        <h1>
          <a href="#main">Главная</a>
        </h1>
        <h1>
          <a href="#">Знакомство</a>
        </h1>
        <h1>
          <a href="#">Проекты</a>
        </h1>
        <h1>
          <a href="#">Контакты</a>
        </h1>
      </div>
    </div>
  )
}

export default Navbar
